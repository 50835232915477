export default defineNuxtRouteMiddleware(async () => {
	const user = useSupabaseUser();

	if (!user.value) {
		return abortNavigation(new Error('Not authenticated'));
	}

	const { data, suspense } = useAdminPrivilegesQuery({ userId: user.value.id });

	await suspense();

	if (!data) {
		return abortNavigation({
			statusCode: 403,
			message: 'User has no admin privileges',
		});
	}
});
